<script>
import {defineComponent} from 'vue'
import PageHeader from "@/components/page-header.vue";
import UsersList from "@/views/pages/accounts/users/components/UsersList.vue";

export default defineComponent({
  name: "users",
  components: {
    PageHeader,
    UsersList
  },
  data() {
    return {
      items: [
        {
          title: 'Users',
          icon: 'fa fa-users'
        },
      ]
    }
  }
})
</script>

<template>
  <PageHeader title="Users" :items="items"/>
  <UsersList/>
</template>

<style scoped>

</style>