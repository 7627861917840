<script>
import {defineComponent} from 'vue'
import axios from "axios";
import SelectCompany from "@/components/custom/SelectCompany.vue";
import Swal from "sweetalert2";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "CreateUser",
  emits: ['onUserCreated'],
  props: {
    company: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      showModal: false,
      available_user_types: [],
      user: {
        email: "",
        first_name: "",
        last_name: "",
        company_id: 0,
        type: "",
        password: "",

        telegram_access: false,
        telegram_id: null
      },

    }
  },
  computed: {
    formIsValid() {
      let isTelegramIdValid = true
      if (this.user.telegram_access) {
        isTelegramIdValid = this.user.telegram_id
      }
      let isCompanyValid = this.company ? this.company.id : this.user.company_id
      return this.user.email.trim().length > 0
          && this.user.first_name.trim().length > 0
          && this.user.last_name.trim().length > 0
          && isCompanyValid
          && this.user.type
          && this.user.password.trim().length > 0
          && isTelegramIdValid
    }
  },
  components: {
    SelectCompany,
    Multiselect
  },
  methods: {
    async addMember() {
      if (!this.formIsValid) return
      try {
        await axios.post('/user/create/', {
          email: this.user.email,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          company_id: this.company ? this.company.id : this.user.company_id,
          type: this.user.type.value,
          password: this.user.password,
          telegram_access: this.user.telegram_access,
          telegram_id: this.user.telegram_id,
        })
        this.showModal = false
        this.user = {
          email: "",
          first_name: "",
          last_name: "",
          company_id: 0,
          type: "",
          password: "",

          telegram_access: false,
          telegram_id: null
        }
        this.$emit('onUserCreated')
        await Swal.fire({
          title: 'Success!',
          text: 'User created successfully',
          icon: 'success',
          confirmButtonText: 'Ok',
          focusConfirm: true,
        })
      } catch (e) {
        try {
          await Swal.fire({
            title: e.response.data.message,
            html: Object.keys(e.response.data.extra.fields)
                .map(field => field.replace('_', ' '))
                .join('<br>'),
            icon: 'warning',
            confirmButtonText: 'Try Again',
            focusConfirm: true,
          })
        } catch {
          await Swal.fire({
            title: 'Error!',
            text: 'Something went wrong',
            icon: 'error',
            confirmButtonText: 'Tye Again',
            focusConfirm: true,
          })
        }
      }

    },
    onCompanySelected(company) {
      if (company) {
        this.user.company_id = company.value
      } else {
        this.user.company_id = null
      }
    },
    async getAvailableUserTypes() {
      try {
        let response = await axios.get('/user/types/')
        this.available_user_types = response.data.results.map(i => {
          return {
            value: i.value,
            label: i.label
          }
        })
      } catch {
        this.available_user_types = []
      }
    }
  },
  mounted() {
    this.getAvailableUserTypes()
  }
})
</script>

<template>
  <b-button
      @click="showModal = true"
      variant="success">Create User
  </b-button>

  <b-modal v-model="showModal" title="User Create Dialog" hide-footer centered>
    <form @submit.prevent="addMember()">
      <div class="row gy-4">
        <div class="col-12">
          <label class="form-label text-dark">
            Email
            <i class="bx bxs-star ms-1 text-warning fw-medium"></i>
          </label>
          <input v-model="user.email" class="form-control" type="email" placeholder="Enter email">
        </div>
        <div class="col-12">
          <label class="form-label text-dark">First Name</label>
          <input v-model="user.first_name" class="form-control" type="text" placeholder="First name">
        </div>
        <div class="col-12">
          <label class="form-label text-dark">Last Name</label>
          <input v-model="user.last_name" class="form-control" type="text" placeholder="Last name">
        </div>
        <div class="col-12">
          <label class="form-label text-dark">Company</label>
          <template v-if="company">
            <SelectCompany disabled
                           :current_company="{id: company.id, name: company.name}"/>
          </template>
          <template v-else>
            <SelectCompany
                @onSelect="onCompanySelected"/>
          </template>
        </div>
        <div class="col-12">
          <label class="form-label text-dark">Choose user type</label>

          <Multiselect
              v-model="user.type"
              v-bind="{
                options: available_user_types,
                placeholder: 'Select user role',
                searchable: false,
                hideSelected: true,
                object: true,
              }"
          ></Multiselect>

        </div>

        <div class="col-12">
          <label class="form-label text-dark">Password</label>
          <input v-model="user.password" class="form-control" type="password" placeholder="Password">
        </div>
        <div class="col-12">
          <div class="form-check mb-3">
            <input v-model="user.telegram_access" class="form-check-input" type="checkbox" id="telegramAccess">
            <label class="form-check-label align-middle text-dark" for="telegramAccess">
              Give this user an access to use telegram bot ?
            </label>
          </div>
          <template v-if="user.telegram_access">
            <label class="form-label text-dark">User's Telegram Id</label>
            <input v-model="user.telegram_id" class="form-control" type="number" placeholder="Telegram id">
          </template>
        </div>
        <div class="col-12 text-end ">
          <b-button :disabled="!formIsValid"
                    type="submit" variant="success">Add Member
          </b-button>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<style scoped>

</style>