<script>
import {defineComponent} from 'vue'
import Table from "@/components/tables/table.vue";
import CreateUser from '@/views/pages/accounts/users/modal/CreateUser.vue'

export default defineComponent({
  name: "users",
  components: {
    Table,
    CreateUser,
  },
  data() {
    return {
      table: {
        name: 'Users Table',
        url: '/user/',
        headers: [
          {
            field: 'username',
            label: 'Username',
            align: 'center',
          },
          {
            field: 'first_name',
            label: 'First Name',
            align: 'center',
          },
          {
            field: 'last_name',
            label: 'Last Name',
            align: 'center',
          },
          {
            field: 'email',
            label: 'Email',
            align: 'center',
          },
          {
            field: 'company',
            label: 'Company',
            keys: ['company', 'name'],
            align: 'center',
          },
          {
            field: 'type',
            label: 'User type',
            align: 'center',
            searchType: 'select',
            searchOptions: [
              {label: 'All', value: ''},
              {label: 'Client', value: 'client'},
              {label: 'Admin', value: 'admin'},
              {label: 'Manager', value: 'manager'},
              {label: 'Counterparty', value: 'counterparty'},
              {label: 'Moscow Manager', value: 'moscow_manager'},
              {label: 'Central Asia Manager', value: 'moscow_manager'},

            ]

          },
          {
            field: 'telegram_access',
            label: 'Telegram Access',
            align: 'center',
            searchType: 'select',
            searchOptions: [
              {label: 'All', value: ''},
              {label: 'Yes', value: 'true'},
              {label: 'No', value: 'false'},
            ],
          },
          {
            field: 'actions',
            label: 'Actions',
            align: 'center',
          },
        ]
      }
    }
  }
})
</script>

<template>
  <div class="mb-4">
    <Table ref="usersListTable"
           :name="table.name"
           :url="table.url"
           :headers="table.headers"
           :selectable="true"
           :searchable="true"
    >
      <template v-slot:top-right>
        <CreateUser @onUserCreated="this.$refs.usersListTable.getData()"/>
      </template>
      <template v-slot:username="{row: user}">
        <router-link v-if="user.slug" :to="{name: 'user_profile', params: {slug: user.slug}}">
          {{ user.username }}
        </router-link>
        <span v-else>
          {{ user.username }}
        </span>
      </template>
      <template v-slot:first_name="{row: user}">
        {{ user.first_name }}
      </template>
      <template v-slot:last_name="{row: user}">
        {{ user.last_name }}
      </template>

      <template v-slot:type="{row: user}">
        <div v-if="user.type">
          <span class="badge bg-success">{{ user.type }}</span>
        </div>
        <span v-else>-</span>
      </template>

      <template v-slot:company="{row: user}">
        <span v-if="user.company === null">--</span>
        <div v-else>
          <router-link :to="{name: 'counterparty_profile_main', params: {slug: user.company.slug}}">
            <div class="d-flex align-items-center justify-content-center">
              <div class="flex-shrink-0 avatar-xxs me-2">
                <div class="avatar-title rounded-circle bg-soft-secondary text-secondary">
                  {{ user.company.name[0] }}
                </div>
              </div>
              <span></span>
              <span class="d-inline-block text-truncate" style="max-width: 150px;">
    {{ user.company.name }}
</span>
            </div>
          </router-link>
        </div>
      </template>

      <template v-slot:telegram_access="{row: user}">
        <i v-if="user.telegram_access" class="ri-telegram-fill fs-22 text-secondary"></i>
        <i v-else class="ri-alert-fill fs-22 text-danger"></i>
      </template>

    </Table>
  </div>
</template>

<style scoped>

</style>